import { useCallback, useEffect, useState } from "react";
import { Box } from "@mui/material";
import { Icon } from "@iconify/react";

import SharpeLogoWhite from "../images/sharpe-logo-white.svg";
import { getSharpePointsForUser } from "../firebase/config";
import ShimmerButton from "./magicui/shimmer-button";
import GlobalSearch from "./GlobalSearch";
import { NavMenu } from "./NavMenu";
import { MagnifyingGlassIcon } from "@heroicons/react/20/solid";

// const CustomTooltip = styled(({ className, ...props }) => (
//   <Tooltip {...props} classes={{ popper: className }} />
// ))(({ theme, mode }) => ({
//   [`& .${tooltipClasses.tooltip}`]: {
//     color: mode === "light" ? "#000" : "#98ECFF",
//     border: "1px solid #98ECFF",
//     background: mode === "light" ? "#98ECFF" : "transparent",
//     padding: "8px 16px",
//     fontSize: "14px",
//     cursor: "default",
//     fontFamily: "Inter, sans-serif",
//     "&:hover": {
//       border: "1px solid #98ECFF",
//     },
//     textTransform: "capitalize",
//     borderRadius: "0",
//   },
//   [`& .${tooltipClasses.arrow}`]: {
//     color: mode === "light" ? "#98ECFF" : "#98ECFF",
//   },
// }));

export default function Header({ onModeChange, walletAddress }) {
  const [mode, setMode] = useState("dark");
  const [sharpePoints, setSharpePoints] = useState(0);
  const [openSearch, setOpenSearch] = useState(false);
  // const isSmallScreen = useIsSmallScreen(600)
  const mainWebsiteApiUrl =
    process.env.NODE_ENV === "production"
      ? process.env.REACT_APP_PUBLIC_API_URL
      : process.env.REACT_APP_PUBLIC_LOCAL_API_URL;

  const handleSearchInputClick = () => {
    setOpenSearch(true);
  };
  const handleCloseSearch = () => {
    setOpenSearch(false);
  };
  // Function to toggle mode
  // const toggleMode = () => {
  //   const newMode = mode === "light" ? "dark" : "light";
  //   setMode(newMode);
  //   onModeChange(newMode);
  // };

  useEffect(() => {
    if (mode === "dark") {
      document.documentElement.classList.add("dark");
    } else {
      document.documentElement.classList.remove("dark");
    }
  }, [mode]);

  const renderIcon = () => {
    if (mode === "light") {
      return (
        <Icon
          icon="tdesign:mode-dark"
          width="22"
          height="22"
          style={{ color: "rgb(74 74 74)" }}
        />
      );
    } else {
      return (
        <Icon
          icon="iconamoon:mode-light"
          width="22"
          height="22"
          style={{ color: "#cfcfcf" }}
        />
      );
    }
  };
  const icon = renderIcon();

  useEffect(() => {
    const fetchSharpePoints = async () => {
      try {
        const points = await getSharpePointsForUser(walletAddress);
        setSharpePoints(points);
      } catch (error) {
        console.error("Error fetching Sharpe points:", error);
      }
    };

    if (walletAddress) {
      fetchSharpePoints();
    }
  }, [walletAddress]);

  const handleKeydown = useCallback(
    (event) => {
      // ** Shortcut keys to open searchbox (Ctrl + /)
      if (
        !openSearch &&
        event.which === 191 &&
        (event.ctrlKey || event.metaKey)
      ) {
        setOpenSearch(true);
      }
    },
    [openSearch]
  );

  const handleKeyUp = useCallback(
    (event) => {
      // ** ESC key to close searchbox
      if (openSearch && event.keyCode === 27) {
        setOpenSearch(false);
      }
    },
    [openSearch]
  );

  useEffect(() => {
    document.addEventListener("keydown", handleKeydown);
    document.addEventListener("keyup", handleKeyUp);

    return () => {
      document.removeEventListener("keydown", handleKeydown);
      document.removeEventListener("keyup", handleKeyUp);
    };
  }, [handleKeyUp, handleKeydown]);

  return (
    <div className="flex items-center w-full mb-[40px] justify-between py-[25px] px-[20px] 2xl:px-[40px]">
      <div className="flex flex-1 items-center gap-2">
        <a href="https://sharpe.ai/" style={{ textDecoration: "none" }}>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <img
              src={SharpeLogoWhite}
              alt="profile"
              style={{ height: "100%", width: "110px" }}
            />
          </Box>
        </a>
        {/* <div
          class="hidden lg:flex max-w-md mx-auto"
          onClick={handleSearchInputClick}
        >
          <div
            className={`search-box relative flex items-center cursor-pointer border ${
              mode === "light" ? "border-neutral-300" : "border-[#27272a]"
            } px-2 py-0 w-full h-10 rounded-lg focus-within:shadow-lg bg-transparent overflow-hidden`}
          >
            <div className="flex items-center">
              <div
                className={`grid place-items-center h-full w-12 ${
                  mode === "light" ? "text-neutral-500" : "text-neutral-300"
                }`}
              >
                <Icon
                  icon="fluent:search-16-regular"
                  width="22"
                  height="22"
                  style={{
                    color: mode === "light" ? "#4a4a4a" : "#fff",
                    cursor: "pointer",
                  }}
                />
              </div>
              <span
                className={`peer h-full w-full outline-none bg-transparent text-sm ${
                  mode === "light" ? "text-neutral-500" : "text-neutral-400"
                } pr-2 pl-1`}
              >
                Search
              </span>
              <p
                className={`text-sm flex items-center gap-1 ${
                  mode === "light" ? "text-neutral-500" : "text-neutral-500"
                }`}
              >
                Press{" "}
                <kbd
                  className={`pointer-events-none inline-flex h-5 select-none items-center gap-1 rounded border ${
                    mode === "light" ? "bg-neutral-100" : "bg-[#27272A]"
                  } px-1.5 font-mono text-[10px] font-medium ${
                    mode === "light" ? "text-neutral-600" : "text-neutral-300"
                  } opacity-100`}
                >
                  <span className="text-md">⌘</span>/
                </kbd>
              </p>
            </div>
          </div>
        </div> */}
      </div>
      {/* <div class="bg-[#fff] dark:bg-[#0C0C0C] my-[10px] ml-16 text-[14px] text-[#000] dark:text-[#EFEFEF] leading-none border-2 border-[#dbdbdb] dark:border-[#131313] rounded-[128px] inline-flex p-[6px] h-[55px]">
        <button
          class="inline-flex items-center transition-colors gap-[8px] duration-300 ease-in focus:outline-none rounded-r-full px-4 py-0"
          id="grid"
          onClick={() => (window.location.href = "https://bridge.sharpe.ai")}
        >
          <Icon
            icon="ic:baseline-swap-horiz"
            width="20"
            height="20"
            style={{ color: mode === "light" ? "#000000" : "#EFEFEF" }}
          />
          <span>Cross-Chain Swap</span>
        </button>
        <button
          class="inline-flex items-center transition-colors gap-1 bg-[#f0f0f0] dark:bg-[#101013] border border-[#dadada] dark:border-[#1E1E1E] rounded-full duration-100 ease-in focus:outline-none text-black dark:text-[#EFEFEF] rounded-l-full px-6 py-0 active"
          id="list"
        >
          <Icon
            icon="ic:baseline-local-gas-station"
            width="20"
            height="20"
            style={{ color: mode === "light" ? "#000000" : "#EFEFEF" }}
          />
          <span>Get Gas</span>
        </button>
      </div> */}
      <NavMenu
        apiUrl={mainWebsiteApiUrl}
        mode={mode}
        customClasses={{
          container: "custom-container-class",
          navSection: {
            button: "custom-button-class",
          },
        }}
      />
      <div className="flex flex-1 items-center justify-end">
        <div
          className="hidden lg:flex w-52 mr-4 ml-4"
          onClick={handleSearchInputClick}
        >
          <label htmlFor="search" className="sr-only">
            Search assets
          </label>
          <div className="relative w-full">
            <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
              <MagnifyingGlassIcon
                className="h-5 w-5 text-gray-400"
                aria-hidden="true"
              />
            </div>
            <input
              id="search"
              name="search"
              className="block w-full rounded-md cursor-pointer border-0 bg-white/5 py-2.5 pl-10 pr-3 text-white placeholder:text-gray-400 outline-none focus:ring-0 caret-transparent sm:text-sm sm:leading-6"
              placeholder="Search assets"
              type="search"
              autoComplete="off"
            />

            <div className="absolute inset-y-0 right-0 flex items-center pr-3">
              <kbd className="inline-flex items-center rounded px-1 font-sans text-xs text-gray-400">
                ⌘K
              </kbd>
            </div>
          </div>
        </div>
        <a
          href="https://stake.sharpe.ai"
          className="rounded-md whitespace-nowrap bg-[#2470FF] px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-[#5792FF] focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-[#2470FF]"
        >
          Earn $SAI
        </a>
      </div>
      <GlobalSearch
        openSearch={openSearch}
        handleClose={handleCloseSearch}
        mode={mode}
      />
    </div>
  );
}
